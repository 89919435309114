define("trivver-frontend/mixins/routes/pp-review-step", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    actions: {
      willTransition: function willTransition(transition) {
        var target = transition.targetName;

        if (target.includes('pp.campaigns.create') || target.includes('pp.campaigns.update')) {
          return this._super(transition);
        } // Rollback all dirty pp-assets


        var model = this.controller.get('model');
        model.campaign.rollbackAttributes();
        model.payment.rollbackAttributes();
        this.store.peekAll('pp-asset').forEach(function (asset) {
          if (asset.get('id') && asset.hasDirtyAttributes) {
            asset.rollbackAttributes();
          }

          if (!asset.get('id')) {
            asset.unloadRecord();
          }
        });
        return this._super(transition);
      }
    }
  });

  _exports.default = _default;
});