define("trivver-frontend/mixins/reaches", ["exports", "trivver-frontend/constants/e-commerce"], function (_exports, _eCommerce) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    estimatedReach: Ember.computed('model.payment.amount', function () {
      var prices = this.get('model.prices');
      var priceFields = this.priceFields;
      var fields = priceFields || ['price_3d', 'price_purchase', 'price_coupon', 'price_smarttab', 'price_video'];
      var total = fields.reduce(function (a, b) {
        return a + prices.get(b);
      }, 0);
      var average = total * (_eCommerce.ESTIMATED_REACH_MULTIPLIER / 100);
      var amount = parseInt(this.get('model.payment.amount') || 0, 0);
      var paid = parseInt(this.get('model.campaign.paid_amount') || 0, 0);
      return (amount + paid) / average;
    }),
    minReach: Ember.computed('model.payment.amount', function () {
      var estimatedReach = this.estimatedReach;

      if (estimatedReach > 0) {
        return estimatedReach * (_eCommerce.ESTIMATED_REACH_MULTIPLIER / 100);
      }

      return estimatedReach;
    })
  });

  _exports.default = _default;
});