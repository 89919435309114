define("trivver-frontend/pods/home/cabinet/advertiser/pp/campaigns/update/review/controller", ["exports", "trivver-frontend/pods/home/cabinet/advertiser/pp/campaigns/mixins/color-settings", "trivver-frontend/pods/home/cabinet/advertiser/pp/campaigns/mixins/existing-product-form", "trivver-frontend/pods/home/cabinet/advertiser/pp/campaigns/mixins/product-form"], function (_exports, _colorSettings, _existingProductForm, _productForm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_productForm.default, _existingProductForm.default, _colorSettings.default, {
    router: Ember.inject.service("-routing"),
    config: Ember.inject.service(),
    finishPageMap: Ember.inject.service(),
    notify: Ember.inject.service(),
    priceFields: ["price_pp_small_2d", "price_pp_full_2d", "price_pp_small_3d", "price_pp_full_3d", "price_pp_ar_link", "price_pp_ar_view", "price_pp_intent_promo", "price_pp_intent_shop", "price_pp_advocacy_share", "price_pp_brand_url", "price_pp_metaverse_visit"],
    campaignTypes: Ember.computed("model.campaign", function () {
      return [{
        value: "P",
        label: "Commerce Portal",
        selected: this.get("model.campaign.type") === "P" ? "selected" : undefined
      }, {
        value: "T",
        label: "AR Viewer",
        selected: this.get("model.campaign.type") === "T" ? "selected" : undefined
      }];
    }),
    redirectLink: Ember.computed("model.campaign", function () {
      return "".concat(window.location.origin, "/v2/advertiser/pp/campaigns/default?campaign=").concat(this.get("model.campaign.id"));
    }),
    campaignUrl: Ember.computed("model.campaign", function () {
      return document.location.host;
    }),
    disableAddFunds: Ember.computed("model.campaign.end", function () {
      return this.get("model.campaign.end") !== null && this.get("model.campaign.end") < Date.now();
    }),
    finishPage: Ember.computed("parent.finishPage", function () {
      var queryParam = this.get("parent.finishPage");
      return this.finishPageMap.getFinishPage(queryParam);
    }),
    changeType: Ember._action(function (event) {
      var selectedValue = event.target.value; // Handle the selected value as needed

      this.set("model.campaign.type", selectedValue);
    })
  });

  _exports.default = _default;
});