define("trivver-frontend/pods/components/models-table/advertiser-campaigns/component", ["exports", "trivver-frontend/pods/components/models-table/advertiser-campaigns/hints"], function (_exports, _hints) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    pageSize: 10,
    useFilteringByColumns: false,
    showGlobalFilter: true,
    filteringIgnoreCase: true,
    showPageSize: false,
    selectRowOnClick: false,
    multipleSelect: false,
    anyFilterUsed: false,
    finishPage: null,
    campaignsData: Ember.computed.reads("model"),
    columns: Ember.computed(function () {
      return [{
        title: "Campaign Name",
        propertyName: "title",
        filterWithSelect: true,
        className: "mt-title orderable",
        component: "models-table/advertiser-campaigns/cells/title",
        finishPage: this.finishPage
      }, {
        title: "Start Date",
        propertyName: "start",
        filterWithSelect: true,
        sortDirection: "desc",
        sortPrecedence: 1,
        className: "mt-start orderable",
        component: "models-table/advertiser-campaigns/cells/date"
      }, {
        title: "End Date",
        propertyName: "end",
        filterWithSelect: true,
        className: "mt-end orderable",
        component: "models-table/advertiser-campaigns/cells/date"
      }, // {
      //   title: htmlSafe("Budgeted<br/>(Remaining)"),
      //   componentForSortCell: "models-table/sort-cell-with-hint",
      //   sortCellHint: HINTS.budgeted,
      //   propertyName: "paid_amount",
      //   lowerPropertyName: "balance",
      //   filterWithSelect: true,
      //   className: "mt-budget orderable",
      //   component: "models-table/advertiser-campaigns/cells/money",
      // },
      {
        title: "BSO",
        componentForSortCell: "models-table/sort-cell-with-hint",
        sortCellHint: _hints.default.bso,
        propertyName: "bso",
        filterWithSelect: true,
        className: "mt-bso orderable",
        component: "models-table/advertiser-campaigns/cells/bso"
      } // {
      //   title: "Spend",
      //   componentForSortCell: "models-table/sort-cell-with-hint",
      //   sortCellHint: HINTS.spend,
      //   filterWithSelect: true,
      //   propertyName: "total_cost",
      //   className: "mt-spend orderable",
      //   component: "models-table/advertiser-campaigns/cells/spend",
      // },
      ];
    })
  });

  _exports.default = _default;
});