define("trivver-frontend/pods/adv-campaign/model", ["exports", "@ember-data/model", "ember-cp-validations", "ember-api-actions", "moment", "accounting", "trivver-frontend/mixins/copyable", "trivver-frontend/constants/date-formats", "trivver-frontend/constants/variables"], function (_exports, _model, _emberCpValidations, _emberApiActions, _moment, _accounting, _copyable, _dateFormats, _variables) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    asset: (0, _emberCpValidations.validator)('belongs-to'),
    title: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
      max: 255
    })],
    budget: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('number', {
      allowString: true,
      gte: 0
    })],
    start: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('campaign-start-date')],
    end: [(0, _emberCpValidations.validator)('end-date'), (0, _emberCpValidations.validator)('dependent', {
      on: ['start']
    })],
    isAgreed: (0, _emberCpValidations.validator)('boolean', {
      presence: true
    }),
    games: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'Select at least one game.'
    }), (0, _emberCpValidations.validator)('has-many', true)]
  }, {
    disabled: Ember.computed('model.validationAttempted', function () {
      return !this.get('model.validationAttempted');
    })
  });

  var _default = _model.default.extend(_copyable.default, Validations, {
    enabled: (0, _model.attr)('boolean', {
      defaultValue: true
    }),
    budget: (0, _model.attr)('cent', {
      defaultValue: 0
    }),
    paid_amount: (0, _model.attr)('cent', {
      defaultValue: 0
    }),
    balance: (0, _model.attr)('cent'),
    title: (0, _model.attr)('string'),
    description: (0, _model.attr)('string'),
    start: (0, _model.attr)('date', {
      defaultValue: function defaultValue() {
        return new Date();
      }
    }),
    end: (0, _model.attr)('date'),
    created: (0, _model.attr)('date'),
    modified: (0, _model.attr)('date'),
    uid: (0, _model.attr)('string'),
    status: (0, _model.attr)('string'),
    asset_id: (0, _model.attr)('number'),
    coupons_enabled: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    bso: (0, _model.attr)('string'),
    action_viewability_count: (0, _model.attr)('number'),
    total_count: (0, _model.attr)('number'),
    total_cost: (0, _model.attr)('number'),
    advertiser: (0, _model.belongsTo)('org', {
      async: true
    }),
    asset: (0, _model.belongsTo)('adv-asset', {
      async: true
    }),
    assets: (0, _model.hasMany)('asset', {
      async: true
    }),
    games: (0, _model.hasMany)('matching-game'),
    // Unserialized fields
    isAgreed: (0, _model.attr)('boolean'),
    amountToPay: Ember.computed('budget', 'paid_amount', function () {
      return (this.budget - this.paid_amount) * 100;
    }),
    startDateTime: Ember.computed('start', function () {
      return (0, _moment.default)(this.start).format(_dateFormats.MAIN_DATE_TIME_FORMAT);
    }),
    endDateTime: Ember.computed('end', function () {
      return this.end ? (0, _moment.default)(this.end).format(_dateFormats.MAIN_DATE_TIME_FORMAT) : '';
    }),
    minStartDate: Ember.computed('start', function () {
      if (this.isStarted || this.isEnded) {
        return new Date(this.start);
      }

      return new Date(Date.now());
    }),
    minEndDate: Ember.computed('start', function () {
      if (!this.start) {
        return new Date(Date.now());
      }

      var date = this.start;

      if (new Date(this.start) < Date.now()) {
        date = Date.now();
      }

      return new Date((0, _moment.default)(date).add(1, 'days'));
    }),
    maxEndDate: Ember.computed('start', function () {
      var date = new Date((0, _moment.default)(Date.now()).add(_variables.END_DATE_CAMPAIGN_LIMIT, 'days'));
      return date;
    }),
    startDate: Ember.computed('start', {
      get: function get() {
        return (0, _moment.default)(this.start).format(_dateFormats.SEND_DATE_FORMAT);
      },
      set: function set() {
        var key = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'start';
        var value = arguments.length > 1 ? arguments[1] : undefined;
        return this.set(key, value);
      }
    }),
    budgetBalance: Ember.computed('paid_amount', 'balance', function () {
      return "".concat(_accounting.default.formatMoney(this.paid_amount, '', 2), "/").concat(_accounting.default.formatMoney(this.balance, '', 2));
    }),
    isStarted: Ember.computed('start', 'hasDirtyAttributes', 'balance', function () {
      return this.start < new Date() && !this.changedAttributes().start && this.balance > 0;
    }),
    isEnded: Ember.computed('end', 'hasDirtyAttributes', 'balance', function () {
      return this.end && this.end < new Date() && !this.changedAttributes().end;
    }),
    isEndedOrStarted: Ember.computed.or('isStarted', 'isEnded'),
    deactivate: (0, _emberApiActions.memberAction)({
      path: 'deactivate/',
      type: 'PUT'
    }),
    resetBudget: function resetBudget() {
      this.set('budget', this.paid_amount);
    }
  });

  _exports.default = _default;
});